// const Steering = () =>
//   import(/* webpackChunkName: "Steering" */ "@/components/rapsosps/Steering");
const Cases = () =>
  import(/* webpackChunkName: "Cases" */ "@/components/rapsosps/Cases");
const Operations = () =>
  import(
    /* webpackChunkName: "Operations" */ "@/components/rapsosps/Operations"
  );
const Referencials = () =>
  import(
    /* webpackChunkName: "Referencials" */ "@/components/rapsosps/Referencials"
  );
const TechnicalDoc = () =>
  import(
    /* webpackChunkName: "TechnicalDoc" */ "@/components/rapsosps/TechnicalDoc"
  );
const MyProfile = () =>
  import(/* webpackChunkName: "MyProfile" */ "@/components/rapsosps/MyProfile");
const OfflineReportGeneration = () =>
  import(
    /* webpackChunkName: "OfflineReportGeneration" */ "@/components/rapsosps/OfflineReportGeneration"
  );

import OperationsChildrens from "./rapsosps/operations-childrens";
import CasesChildrens from "./rapsosps/cases-childrens";
import ReferencialChildrens from "./rapsosps/referencial-childrens";
import isTechnicalDirection from "@/router/guards/isTechnicalDirection";
import store from "@/store";

const fetchStaticModels = async (to, from, next) => {
  if (store.state.network.isOnline){
    await store.dispatch("document/fetchDocumentTypes");
    await store.dispatch("document/fetchDocumentStatus");
  }
  next();
};

export default [
  {
    path: "",
    name: "home",
    // INFO - M.S - 22/04/2021
    // This redirection for fix the active class that is always active on "/"
    redirect: { name: "operationsList" },
  },
  // {
  //   path: "dashboard",
  //   name: "dashboard",
  //   component: Steering,
  // },
  {
    path: "operations",
    component: Operations,
    children: OperationsChildrens,
    beforeEnter: fetchStaticModels,
  },
  {
    path: "cases",
    component: Cases,
    children: CasesChildrens,
    beforeEnter: fetchStaticModels,
  },
  {
    path: "referencials",
    component: Referencials,
    children: ReferencialChildrens,
    beforeEnter: isTechnicalDirection,
  },
  {
    path: "technical-documentation/:type",
    name: "technical-document",
    component: TechnicalDoc,
    beforeEnter: isTechnicalDirection,
  },
  {
    path: "edit-profile",
    name: "edit-profile",
    component: MyProfile,
  },
  {
    path: "offline-report-generation/:templateCode/:step/",
    props: true,
    name: "offline-report-generation",
    component: OfflineReportGeneration,
  },
];
